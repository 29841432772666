(function($) {

    $(document).ready(function() {
        $('.nav-toggle, .nav-overlay').on('click', function(){
            $(".nav").toggleClass('-closed');
            $(".nav-overlay").toggleClass('-closed');
        });
    });

})(jQuery);
