$('.map').mapify({
    points: [
        {
            lat: 54.00158,
            lng: -0.920750,
            marker: true,
        }
    ],
    key: 'AIzaSyDeHV-77lYokJImQEgiSkk4x1m60CTOzJA',
    zoom: 12
});